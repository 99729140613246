import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import { useState, useEffect, useRef } from 'react';
import RadioButtonBlock from "../Misc/RadioButtonBlock";
import CheckBoxBlock from "../Misc/CheckBoxBlock";
import EmptyButton from '../Misc/EmptyButton';
import PromoCode from '../Misc/PromoCode';
import {
  setSbscSwap,
  setSbscMediaQuota,
  setSbscCost,
  setSbscUserNotificationEmail,
  setSbscUserNotificationSMS,
  setSbscUserNotificationWeb,
  setSbscUserNotificationAddOn,
  setSbscUserFrequency,
  setSbscUserAddOnAvailable,
  setSbscPromoCode
} from "../../storage/redux/slice/subscriptionSlice";
import { useGetProductPriceInfoQuery, useApplyPromoCodeMutation, useGetPromoCodeFromAccQuery } from "../../Apis/paymentsApi";
import MainLoader from "../Misc/MainLoader";
import { useNavigate } from "react-router-dom";
import { withAuth } from '../../HOC';
import { RootState } from "../../storage/redux/store";
import { useSelector, useDispatch } from "react-redux";
import { userModel } from "../../Interfaces";
import { W0, W1, W2, CalculateCost } from "../../functions";


function CreateAccountI({ subscriptionData }: any) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState("");
  const [wrongCode, setWrongCode] = useState("");
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );

  const cbhandler_swap = (e: any) => {
    dispatch(setSbscSwap(""));
  }

  const promocodefromaccount: any = useGetPromoCodeFromAccQuery(userData.id);

  const [applyPromoCode] = useApplyPromoCodeMutation();

  let temp1;
  if (subscriptionData.promocode === "") { temp1 = "current" } else { temp1 = subscriptionData.promocode; }
  const productandpricedetail: any = useGetProductPriceInfoQuery(temp1);

  useEffect(() => {
    dispatch(setSbscPromoCode(promocodefromaccount.data));
    setPromoCode(promocodefromaccount.data);
    CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, null, subscriptionData.promocode);
  }, [subscriptionData, promocodefromaccount, productandpricedetail]);




  const ChangeMediaQuota = (value: any) => {
    dispatch(setSbscMediaQuota(value));
  }
  const onBlur = (value: any) => {
    if (value === 0) {
      dispatch(setSbscMediaQuota(10));
    };
  }

  const applyhandler = async () => {
    if (subscriptionData.promocode === "") {
      const response: any = await applyPromoCode({
        promo_code: promoCode,
        application_user_id: userData.id,
        remove_promo_code: false
      });

      //setIsLoading(false);
      if (response.data) {
        dispatch(setSbscPromoCode(promoCode));
      } else {
        setWrongCode("Wrong code.");
      }
      setPromoCode("");
    } else {
      // remove any promo code from Accounts
      const response: any = await applyPromoCode({
        promo_code: "",
        application_user_id: userData.id,
        remove_promo_code: true
      });
      dispatch(setSbscPromoCode(""));
    }
  }

  const promocodeinputhandler = (e: any) => {
    setPromoCode(e.target.value);
  }


  useEffect(() => {
    CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, null, subscriptionData.promocode);
  }, [productandpricedetail]);

  const rbhandler_fr = (e: any) => {
    let frequencyOnDemand = false;
    let frequency3Times = false;
    let frequencyDaily = false;
    let frequencyWeekly = false;
    let frequencyMonthly = false;
    const [btnid, userid] = e.target.id.split("_");
    dispatch(setSbscUserAddOnAvailable({ userid: userid, state: true }));
    if (btnid.includes("rbondemand")) {
      frequencyOnDemand = true;
      if (subscriptionData.users[0].notificationAddOn) {
        dispatch(setSbscUserNotificationAddOn(userid));
      }
      dispatch(setSbscUserAddOnAvailable({ userid: userid, state: false }));
    }
    if (btnid.includes("rb3times")) { frequency3Times = true; }
    if (btnid.includes("rbdaily")) { frequencyDaily = true; }
    if (btnid.includes("rbweekly")) { frequencyWeekly = true; }
    if (btnid.includes("rbmontly")) { frequencyMonthly = true; }

    dispatch(setSbscUserFrequency(
      {
        "userid": userid,
        "frequencyOnDemand": frequencyOnDemand,
        "frequency3Times": frequency3Times,
        "frequencyDaily": frequencyDaily,
        "frequencyWeekly": frequencyWeekly,
        "frequencyMonthly": frequencyMonthly,
      }));
  }

  const cbhandler_nt = (e: any) => {
    const [btnid, userid] = e.target.id.split("_");
    if (btnid === "cbweb") { dispatch(setSbscUserNotificationWeb(userid)); }
    if (btnid === "cbemail") { dispatch(setSbscUserNotificationEmail(userid)); }
    if (btnid === "cbsms") { dispatch(setSbscUserNotificationSMS(userid)); }
    CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, null, subscriptionData.promocode);
  }

  const cbhandler_addon = (e: any) => {
    const [btnid, userid] = e.target.id.split("_");
    dispatch(setSbscUserNotificationAddOn(userid));
    CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, null, subscriptionData.promocode);
  }

  const ContinueClick = () => {
    navigate("/CheckoutI");
  }


  let cbbuttons_swap = [{ "id": "cb1swap", "title": "(free)", "buttonstate": subscriptionData.swap, "buttonhandler": cbhandler_swap },]

  /************************/

  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  let mult = 1;
  if (windowWidth < W1) { mult = W1 / windowWidth; }
  if (windowWidth < W2) { mult = W1 / W2; }
  /******************************************************************************/
  let rbuttons_fr = [];
  let cbbuttons_nt = [];
  let cbbuttons_addon = [];
  if (subscriptionData.users.length > 0) {
    rbuttons_fr = [
      { "id": "rbondemand_" + subscriptionData.users[0].userTempId, "title": "On demand", "buttonstate": subscriptionData.users[0].frequencyOnDemand, "buttonhandler": rbhandler_fr },
      { "id": "rb3times_" + subscriptionData.users[0].userTempId, "title": "3 times/day", "buttonstate": subscriptionData.users[0].frequency3Times, "buttonhandler": rbhandler_fr },
      { "id": "rbdaily_" + subscriptionData.users[0].userTempId, "title": "Daily", "buttonstate": subscriptionData.users[0].frequencyDaily, "buttonhandler": rbhandler_fr },
      { "id": "rbweekly_" + subscriptionData.users[0].userTempId, "title": "Weekly", "buttonstate": subscriptionData.users[0].frequencyWeekly, "buttonhandler": rbhandler_fr },
      { "id": "rbmontly_" + subscriptionData.users[0].userTempId, "title": "Monthly", "buttonstate": subscriptionData.users[0].frequencyMonthly, "buttonhandler": rbhandler_fr }
    ];
    cbbuttons_addon = [
      { "id": "ntaddon_" + subscriptionData.users[0].userTempId, "title": "On Demand add-on. For 3 times/day, Daily, Weekly or Monthly plans only", "buttonstate": subscriptionData.users[0].notificationAddOn, "buttonhandler": cbhandler_addon, "avail": subscriptionData.users[0].addOnAvailable },
    ];
    cbbuttons_nt = [
      { "id": "cbweb_" + subscriptionData.users[0].userTempId, "title": "Web (free)", "buttonstate": subscriptionData.users[0].notificationWeb, "buttonhandler": cbhandler_nt },
      { "id": "cbemail_" + subscriptionData.users[0].userTempId, "title": "Email", "buttonstate": subscriptionData.users[0].notificationEmail, "buttonhandler": cbhandler_nt },
      { "id": "cbsms_" + subscriptionData.users[0].userTempId, "title": "SMS/Text", "buttonstate": subscriptionData.users[0].notificationSMS, "buttonhandler": cbhandler_nt }
    ];
  } else {
    return (<MainLoader />);
  }
  const betweenlines = 12;
  const betweenlines2 = 6;
  const paddingleft = 70;
  if (windowWidth > W0) {
    return (
      <>
        <div className="container-row-bottom" style={{ margin: 20 / mult }}>
        </div>

        <div className="container-row-bottom">
          <div className="icon-portfolio" style={{ width: 50 / mult, height: 50 / mult }}>
          </div>
          &nbsp;&nbsp;&nbsp;Number of images in my porfolio DejaVu.art will search the Internet for:&nbsp;&nbsp;
          <input
            type="text"
            id="imagemunber_i"
            value={subscriptionData.mediaQuota}
            onChange={(e) => { ChangeMediaQuota(Number(e.target.value)) }}
            onBlur={(e) => { onBlur(Number(e.target.value)) }}
            required
            style={{ width: 65 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }}
          />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>
        <div className="container-row-bottom">
          <div className="icon-unlock" style={{ width: 35 / mult, height: 45 / mult }}>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I would like to  change (or "swap") images in my portfolio:&nbsp;&nbsp;
          <CheckBoxBlock buttonsarray={cbbuttons_swap} layout={"column"} />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>


        <div className="container-row-bottom">
          <div className="icon-clock" style={{ width: 50 / mult, height: 55 / mult }}>
          </div>
          &nbsp;&nbsp;&nbsp;Search how often?
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
        </div>
        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult }}>
          <RadioButtonBlock buttonsarray={rbuttons_fr} layout={"row"} />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>
        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult, justifyContent: 'space-between' }}>
          <CheckBoxBlock buttonsarray={cbbuttons_addon} layout={"row"} />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>


        <div className="container-row-bottom">
          <div className="icon-mailunread" style={{ width: 50 / mult, height: 40 / mult }}>
          </div>
          &nbsp;&nbsp;&nbsp;I would like to be notified through...
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
        </div>
        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult, justifyContent: 'space-between' }}>
          <CheckBoxBlock buttonsarray={cbbuttons_nt} layout={"row"} />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>


        <div className="container-row-bottom" style={{ width: '100%', marginTop: 25 / mult, justifyContent: 'center' }}>
          <EmptyButton nav={ContinueClick} title={"Continue"} id={""} classname={"emptybuttonart"} />
        </div>

        <div className='container-row-center'
          style={{
            width: 180 / mult, height: 180 / mult, border: '3px solid #a6abb9', borderRadius: 15 / mult,
            position: 'relative', top: -535 / mult, left: 920 / mult
          }}  >
          <span style={{ textAlign: 'center', color: '#e6e6e6', fontSize: 30 / mult }}>Price:<br />${subscriptionData.cost / 100}</span>
        </div>
        <br />

        <PromoCode mult={mult} applyhandler={applyhandler} promocodeinputhandler={promocodeinputhandler} text={subscriptionData.promocode} wrongcode={wrongCode} promocodevalue={promoCode} />

      </>
    );
  } else {
    // Mobile *************************************************************
    mult = W0 / windowWidth;
    return (
      <>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>
        <span style={{ fontSize: 30 / mult }}>Price:&nbsp;&nbsp;${subscriptionData.cost / 100}</span>
        <PromoCode mult={mult} applyhandler={applyhandler} promocodeinputhandler={promocodeinputhandler} text={subscriptionData.promocode} wrongcode={wrongCode} promocodevalue={promoCode} mobile={true} />

        <div className="container-row-bottom" style={{ marginTop: 15 }}>
          <div className="icon-portfolio" style={{ width: 50 / mult, height: 50 / mult }}>
          </div>
          &nbsp;&nbsp;&nbsp;Number of images for search:&nbsp;&nbsp;
          <input
            type="text"
            id="imagemunber_i"
            value={subscriptionData.mediaQuota}
            onChange={(e) => { ChangeMediaQuota(Number(e.target.value)) }}
            onBlur={(e) => { onBlur(Number(e.target.value)) }}
            required
            style={{ width: 65 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }}
          />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>
        <div className="container-row-bottom">
          <div className="icon-unlock" style={{ width: 35 / mult, height: 45 / mult }}>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Swap included
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>


        <div className="container-row-bottom">
          <div className="icon-clock" style={{ width: 50 / mult, height: 55 / mult }}>
          </div>
          &nbsp;&nbsp;&nbsp;Search how often?
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
        </div>
        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult }}>
          <RadioButtonBlock buttonsarray={rbuttons_fr} layout={"column"} />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>
        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult, justifyContent: 'space-between' }}>
          <CheckBoxBlock buttonsarray={cbbuttons_addon} layout={"row"} bigfix={true} />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>


        <div className="container-row-bottom">
          <div className="icon-mailunread" style={{ width: 50 / mult, height: 40 / mult }}>
          </div>
          &nbsp;&nbsp;&nbsp;I would like to be notified through...
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
        </div>

        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult, justifyContent: 'space-between' }}>
          <CheckBoxBlock buttonsarray={cbbuttons_nt} layout={"column"} bigfix={true} />
        </div>
        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
        </div>

        <div className="container-row-bottom" style={{ width: '100%', marginTop: 25 / mult, justifyContent: 'center' }}>
          <EmptyButton nav={ContinueClick} title={"Continue"} id={""} classname={"emptybuttonart"} />
        </div>
      </>
    );
  }
}

export default withAuth(CreateAccountI);

/*
  const CalculateCost = () => {

    if (productandpricedetail.data !== undefined) {
      console.log("productandpricedetail.data");
      console.log(productandpricedetail.data);
      let totalcost = 0;
      
      for (let element of productandpricedetail.data.prices[9].tiers) {
        let up_to = element.up_to;
        if (element.up_to === null) { up_to = 1000000; }
        if (subscriptionData.userNumber <= up_to) {
          totalcost += subscriptionData.userNumber * element.unit_amount;
          break;
        }
      }
      for (let element of productandpricedetail.data.prices[1].tiers) {
        let up_to = element.up_to;
        if (element.up_to === null) { up_to = 1000000; }
        if (subscriptionData.mediaQuota <= up_to) {
          totalcost += subscriptionData.mediaQuota * element.unit_amount;
          break;
        }
      }

      for (let element of subscriptionData.users) {
        if (element.notificationAddOn) { totalcost += productandpricedetail.data.prices[0].unit_amount }
        if (element.notificationSMS) { totalcost += productandpricedetail.data.prices[2].unit_amount }
        if (element.notificationEmail) { totalcost += productandpricedetail.data.prices[3].unit_amount }
        if (element.frequencyMonthly) { totalcost += productandpricedetail.data.prices[4].unit_amount }
        if (element.frequencyWeekly) { totalcost += productandpricedetail.data.prices[5].unit_amount }
        if (element.frequencyDaily) { totalcost += productandpricedetail.data.prices[6].unit_amount }
        if (element.frequency3Times) { totalcost += productandpricedetail.data.prices[7].unit_amount }
        if (element.frequencyOnDemand) { totalcost += productandpricedetail.data.prices[8].unit_amount }
      }

      dispatch(setSbscCost(totalcost));
    }
  }

*/