import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import { useState, useEffect, useRef } from 'react';
import RadioButtonBlock from "../Misc/RadioButtonBlock";
import CheckBoxBlock from "../Misc/CheckBoxBlock";
import { useScrollbar } from '../../Hooks/use-scrollbar-y';
import { userBigModel } from "../../Interfaces";
import EmptyButton from '../Misc/EmptyButton';
import PromoCode from '../Misc/PromoCode';
import {
    setSbscUsers,
    setSbscUserNumber,
    setSbscSwap,
    setSbscMediaQuota,
    setSbscCost,
    setSbscUserRole,
    setSbscUserNotificationAddOn,
    setSbscUserNotificationEmail,
    setSbscUserNotificationSMS,
    setSbscUserNotificationWeb,
    setSbscUserFrequency,
    setSbscUserConfPassword,
    setSbscUserPassword,
    setSbscUserStatus,
    setSbscUserEmail,
    setSbscUserAddOnAvailable,
    setSbscPromoCode
} from "../../storage/redux/slice/subscriptionSlice";
import { useNavigate } from "react-router-dom";
import { useCheckEmailsMutation } from "../../Apis/authApi";
import { useGetProductPriceInfoQuery, useApplyPromoCodeMutation, useGetPromoCodeFromAccQuery } from "../../Apis/paymentsApi";
import { RootState } from "../../storage/redux/store";
import { useSelector, useDispatch } from "react-redux";
import { userModel } from "../../Interfaces";
import SmallButton from '../Misc/SmallButton';
import SimpleMsg1 from "../Misc/SimpleMsg1";
import { v4 as uuid } from 'uuid';
import { withAuth } from '../../HOC';
import { W0, W1, W2, CalculateCost } from "../../functions";


function UpgradeAccountB({ subscriptionData }: any) {

    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checkEmails] = useCheckEmailsMutation();
    const [promoCode, setPromoCode] = useState("");
    const [wrongCode, setWrongCode] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };


    //dispatch(setSbscCost(0));
    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
    );


    const [messageBox, setMessageBox] = useState(false);

    const cbhandler_swap = (e: any) => {
        dispatch(setSbscSwap(""));
    }


    const promocodefromaccount: any = useGetPromoCodeFromAccQuery(userData.id);

    const [applyPromoCode] = useApplyPromoCodeMutation();

    let temp1;
    if (subscriptionData.promocode === "") { temp1 = "current" } else { temp1 = subscriptionData.promocode; }
    const productandpricedetail: any = useGetProductPriceInfoQuery(temp1);

    useEffect(() => {
        dispatch(setSbscPromoCode(promocodefromaccount.data));
        setPromoCode(promocodefromaccount.data);
        CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, null, subscriptionData.promocode);
    }, [subscriptionData, promocodefromaccount, productandpricedetail]);

    const applyhandler = async () => {
        if (subscriptionData.promocode === "") {
            const response: any = await applyPromoCode({
                promo_code: promoCode,
                application_user_id: userData.id,
                remove_promo_code: false
            });

            //setIsLoading(false);
            if (response.data) {
                dispatch(setSbscPromoCode(promoCode));
            } else {
                setWrongCode("Wrong code.");
            }
            setPromoCode("");
        } else {
            // remove any promo code from Accounts
            const response: any = await applyPromoCode({
                promo_code: "",
                application_user_id: userData.id,
                remove_promo_code: true
            });
            dispatch(setSbscPromoCode(""));
        }
    }

    const promocodeinputhandler = (e: any) => {
        setPromoCode(e.target.value);
    }

    const removeUserFromSbsrList = (e: any) => {
        let i;
        let temparray = [];
        const [btnid, userid] = e.target.id.split("_");
        for (i = 0; i < subscriptionData.users.length; i++) {
            if (subscriptionData.users[i].userTempId !== e.target.id) {
                temparray.push(subscriptionData.users[i]);
            }
        }
        dispatch(setSbscUsers(temparray));
        dispatch(setSbscUserNumber(temparray.length));
    }
    const deactivateUserFromSbsrList = (e: any) => {
        const [btnid, userid] = e.target.id.split("_");
        dispatch(setSbscUserStatus({ "userid": userid, "data": 2 }));

    }
    const activateUserFromSbsrList = (e: any) => {
        const [btnid, userid] = e.target.id.split("_");
        dispatch(setSbscUserStatus({ "userid": userid, "data": 1 }));
    }

    const ChangeMediaQuota = (value: any) => {
        dispatch(setSbscMediaQuota(value));
    }
    const onBlur = (value: any) => {
        if (value === 0) {
            dispatch(setSbscMediaQuota(10));
        };
    }
    const ChangeUserAccounts = (value: any) => {
        let numberofnewusers;
        if (value < 31) {
            if (value >= subscriptionData.userNumber) {
                numberofnewusers = value - subscriptionData.userNumber;
                dispatch(setSbscUserNumber(value));

                let temp: Array<userBigModel> = [];
                let bigUser: userBigModel
                subscriptionData.users.forEach((element: userBigModel) => {
                    temp.push(element);
                });
                for (let i = 0; i < numberofnewusers; i++) {
                    bigUser = {
                        email: "",
                        lastName: "",
                        firstName: "",
                        phoneNumber: "",
                        twoFactorEnabled: false,
                        emailConfirmed: false,
                        phoneNumberConfirmed: false,
                        password: "",
                        confpassword: "",
                        userStatus: 0,

                        notificationAddOn: false,
                        notificationWeb: true,
                        notificationEmail: false,
                        notificationSMS: false,
                        frequencyOnDemand: false,
                        frequency3Times: false,
                        frequencyDaily: false,
                        frequencyWeekly: true,
                        frequencyMonthly: false,

                        primaryUser: false,
                        role: "user",
                        userTempId: uuid().toString()
                    };

                    temp.push(bigUser);
                }
                dispatch(setSbscUsers(temp));
            } else if (subscriptionData.userNumber > 1) {
                setError("To decrease number of required users, please remove user forms  manually.");
                setMessageBox(true);
            }
            CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, null, subscriptionData.promocode);
        }
        // endo of ChangeUserAccounts
    }
    /*********************************************************************/

    /**************** Scroll bar  things*********************************/
    const subscr_b_accWrapper = useRef(null);
    const hasScroll = true;
    useScrollbar(subscr_b_accWrapper, hasScroll);
    /**********************************************************************/


    const emailhandler = (e: any) => {
        const [btnid, userid] = e.target.id.split("_");
        dispatch(setSbscUserEmail({ "userid": userid, "data": e.target.value }));
    }
    const p1handler = (e: any) => {
        const [btnid, userid] = e.target.id.split("_");
        dispatch(setSbscUserPassword({ "userid": userid, "data": e.target.value }));
    }
    const p2handler = (e: any) => {
        const [btnid, userid] = e.target.id.split("_");
        dispatch(setSbscUserConfPassword({ "userid": userid, "data": e.target.value }));
    }

    const rbhandler_r = (e: any) => {
        const [btnid, userid] = e.target.id.split("_");
        if (btnid === "rbuser") { dispatch(setSbscUserRole({ "userid": userid, "role": "user" })); }
        if (btnid === "rbadmin") { dispatch(setSbscUserRole({ "userid": userid, "role": "admin" })); }
        if (btnid === "rbmng") { dispatch(setSbscUserRole({ "userid": userid, "role": "mng" })); }
    }

    const rbhandler_fr = (e: any) => {
        let frequencyOnDemand = false;
        let frequency3Times = false;
        let frequencyDaily = false;
        let frequencyWeekly = false;
        let frequencyMonthly = false;
        const [btnid, userid] = e.target.id.split("_");
        dispatch(setSbscUserAddOnAvailable({ userid: userid, state: true }));
        if (btnid.includes("rbondemand")) {
            frequencyOnDemand = true;

            subscriptionData.users?.forEach((element: userBigModel) => {
                if (element.userTempId === userid) {
                    if (element.notificationAddOn) {
                        dispatch(setSbscUserNotificationAddOn(userid));
                    }
                }
            })
            dispatch(setSbscUserAddOnAvailable({ userid: userid, state: false }));
        }
        if (btnid.includes("rb3times")) { frequency3Times = true; }
        if (btnid.includes("rbdaily")) { frequencyDaily = true; }
        if (btnid.includes("rbweekly")) { frequencyWeekly = true; }
        if (btnid.includes("rbmontly")) { frequencyMonthly = true; }

        dispatch(setSbscUserFrequency(
            {
                "userid": userid,
                "frequencyOnDemand": frequencyOnDemand,
                "frequency3Times": frequency3Times,
                "frequencyDaily": frequencyDaily,
                "frequencyWeekly": frequencyWeekly,
                "frequencyMonthly": frequencyMonthly,
            }));
    }

    const cbhandler_nt = (e: any) => {
        const [btnid, userid] = e.target.id.split("_");
        if (btnid === "cbweb") { dispatch(setSbscUserNotificationWeb(userid)); }
        if (btnid === "cbemail") { dispatch(setSbscUserNotificationEmail(userid)); }
        if (btnid === "cbsms") { dispatch(setSbscUserNotificationSMS(userid)); }
        CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, null, subscriptionData.promocode);
    }

    const cbhandler_addon = (e: any) => {
        const [btnid, userid] = e.target.id.split("_");
        dispatch(setSbscUserNotificationAddOn(userid));
        CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, null, subscriptionData.promocode);
    }

    const ContinueClick = () => {
        let emaillist: Array<string> = [];
        emaillist.push(userData.email);
        let er = false;
        for (let i = 0; i < subscriptionData.users.length; i++) {
            if (!subscriptionData.users[i].primaryUser && subscriptionData.users[i].userStatus === 0) {

                if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(subscriptionData.users[i].email))) {
                    setError("Error: You have entered an invalid email address!");
                    setMessageBox(true);
                    er = true;
                    //return;
                } else if (subscriptionData.users[i].password !== subscriptionData.users[i].confpassword) {
                    setError("Error: Password mismatch");
                    setMessageBox(true);
                    er = true;
                    //return;
                } else if (subscriptionData.users[i].password === "") {
                    setError("Error: Password field is empty");
                    setMessageBox(true);
                    er = true;
                    //return;
                }
            }
        }
        // create  list of  emails for new accounts
        subscriptionData.users.forEach((element1: userBigModel) => {
            if (!element1.primaryUser) {
                emaillist.push(element1.email);
            }
        })
        if (!er) { navigate("/CheckoutB"); }
        /*
        const response: any = checkEmails(emaillist);
        if (response.data) {
          navigate("/CheckoutB");
        } else if (response.error) {
          setError("Error: Email " + response.error.data + " belongs to another account.");
          setMessageBox(true);
        } else {
          setError("UnknownError");
          setMessageBox(true);
        }
        */
    }

    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    let mult = 1;
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    //let mult = 1;
    //if (windowWidth < 1100) { mult = 1100 / windowWidth; }
    let mult1 = 2500 / windowWidth;
    /******************************************************************************/
    const betweenlines = 12;
    const betweenlines2 = 6;
    const paddingleft = 70;

    let cbbuttons_swap = [{ "id": "cb1swap", "title": "(free)", "buttonstate": subscriptionData.swap, "buttonhandler": cbhandler_swap },]

    if (windowWidth > W0) {
        return (
            <>
                <div style={{ width: 1135 / mult, height: 550 / mult, minHeight: 550 / mult }} ref={subscr_b_accWrapper}>
                    <div className="" style={{ width: 1100 / mult, height: 550 / mult, }}>

                        <div className="container-row-bottom" style={{ margin: 20 / mult }}>
                        </div>
                        <div className="container-row-bottom">
                            <div className="icon-group" style={{ width: 40 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;The business that I represent needs&nbsp;&nbsp;
                            <input
                                type="number"
                                id="imagemunber_i"
                                value={subscriptionData.userNumber}
                                onChange={(e) => { ChangeUserAccounts(Number(e.target.value)) }}
                                required
                                style={{ width: 65 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }}
                            />  &nbsp;&nbsp;  user accounts
                        </div>

                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>

                        <div className="container-row-bottom">
                            <div className="icon-portfolio" style={{ width: 50 / mult, height: 50 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;Number of images in my porfolio DejaVu.art will search the Internet for:&nbsp;&nbsp;
                            <input
                                type="text"
                                value={subscriptionData.mediaQuota}
                                id="imagemunber_i"
                                onChange={(e) => { ChangeMediaQuota(Number(e.target.value)) }}
                                onBlur={(e) => { onBlur(Number(e.target.value)) }}
                                required
                                style={{ width: 65 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }}
                            />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                        <div className="container-row-bottom">
                            <div className="icon-unlock" style={{ width: 35 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I would like to change (or "swap") images in my portfolio:&nbsp;&nbsp;
                            <CheckBoxBlock buttonsarray={cbbuttons_swap} layout={"column"} />
                        </div>
                        {(subscriptionData.users.length > 1) &&
                            <div className="container-row-bottom">
                                <button style={{ borderRadius: '8px', marginTop: '25px', cursor: 'pointer' }} onClick={togglePassword}>{passwordShown ? "Hide Passwords" : "Show  Passwords"}</button>
                            </div>
                        }

                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                        {subscriptionData.users.map((user: any) => {
                            return (
                                <UserBox
                                    user={user}
                                    emailhandler={emailhandler}
                                    p1handler={p1handler}
                                    p2handler={p2handler}
                                    rbhandler_fr={rbhandler_fr}
                                    cbhandler_addon={cbhandler_addon}
                                    rbhandler_r={rbhandler_r}
                                    cbhandler_nt={cbhandler_nt}
                                    betweenlines={betweenlines}
                                    betweenlines2={betweenlines2}
                                    paddingleft={paddingleft}
                                    remove={removeUserFromSbsrList}
                                    deactivate={deactivateUserFromSbsrList}
                                    activate={activateUserFromSbsrList}
                                    mult={mult}
                                    passwordShown={passwordShown}
                                    togglePassword={togglePassword}
                                    windowWidth={windowWidth}
                                />

                            );
                        })}
                        <div className="container-row-bottom" style={{ width: '100%', marginTop: 25 / mult, justifyContent: 'center' }}>
                            <EmptyButton nav={ContinueClick} title={"Continue"} id={""} classname={"emptybuttonart"} />
                        </div>
                    </div>
                </div>
                <div className='container-row-center'
                    style={{
                        width: 180 / mult, height: 180 / mult, border: '3px solid #a6abb9', borderRadius: 15 / mult,
                        position: 'relative', top: -585 / mult, left: 920 / mult
                    }}
                >
                    <span style={{ textAlign: 'center', color: '#e6e6e6', fontSize: 30 / mult }}>Price:<br />${subscriptionData.cost / 100}</span>
                </div>

                <PromoCode mult={mult} applyhandler={applyhandler} promocodeinputhandler={promocodeinputhandler} text={subscriptionData.promocode} promocodevalue={promoCode} />

                {messageBox &&
                    <div className='container-column' style={{
                        width: 500 / mult, height: 250 / mult, border: '3px solid #a6abb9', backgroundColor: 'black', borderRadius: 15 / mult, padding: 15 / mult,
                        position: 'relative', top: -685 / mult, left: 250 / mult1
                    }}
                    >
                        <span style={{ textAlign: 'center', color: '#e6e6e6', fontSize: 30 / mult, marginBottom: 40 / mult }}>
                            {error}
                        </span>
                        <EmptyButton nav={() => { setMessageBox(false); setError("") }} title={"Continue"} id={""} classname={"emptybuttonart"} />
                    </div>
                }
            </>
        );
    } else {
        // Mobile *************************************************************
        mult = 600 / windowWidth;
        return (
            <>
                <span style={{ fontSize: 30 / mult }}>Price:&nbsp;&nbsp;${subscriptionData.cost / 100}</span>
                <PromoCode mult={mult} applyhandler={applyhandler} promocodeinputhandler={promocodeinputhandler} text={subscriptionData.promocode} promocodevalue={promoCode} mobile={true} />
                <div className="container-row-bottom">
                    <div className="icon-group" style={{ width: 40 / mult, height: 45 / mult, marginTop: 10 }}>
                    </div>
                    <span>&nbsp;&nbsp;&nbsp;The business needs</span>&nbsp;&nbsp;
                    <input
                        type="number"
                        id="imagemunber_i"
                        value={subscriptionData.userNumber}
                        onChange={(e) => { ChangeUserAccounts(Number(e.target.value)) }}
                        required
                        style={{ width: 65 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }}
                    />  &nbsp;&nbsp;user accounts
                </div>

                <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                </div>

                <div className="container-row-bottom">
                    <div className="icon-portfolio" style={{ width: 50 / mult, height: 50 / mult }}>
                    </div>
                    &nbsp;&nbsp;&nbsp;Number of images for search:&nbsp;&nbsp;
                    <input
                        type="text"
                        value={subscriptionData.mediaQuota}
                        id="imagemunber_i"
                        onChange={(e) => { ChangeMediaQuota(Number(e.target.value)) }}
                        onBlur={(e) => { onBlur(Number(e.target.value)) }}
                        required
                        style={{ width: 65 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }}
                    />
                </div>
                <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                </div>
                <div className="container-row-bottom">
                    <div className="icon-unlock" style={{ width: 35 / mult, height: 45 / mult }}>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Swap included
                </div>
                {(subscriptionData.users.length > 1) &&
                    <div className="container-row-bottom">
                        <button style={{ borderRadius: '8px', marginTop: '25px', cursor: 'pointer' }} onClick={togglePassword}>{passwordShown ? "Hide Passwords" : "Show  Passwords"}</button>
                    </div>
                }

                <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                </div>
                {subscriptionData.users.map((user: any) => {
                    return (
                        <UserBox
                            user={user}
                            emailhandler={emailhandler}
                            p1handler={p1handler}
                            p2handler={p2handler}
                            rbhandler_fr={rbhandler_fr}
                            cbhandler_addon={cbhandler_addon}
                            rbhandler_r={rbhandler_r}
                            cbhandler_nt={cbhandler_nt}
                            betweenlines={betweenlines}
                            betweenlines2={betweenlines2}
                            paddingleft={paddingleft}
                            remove={removeUserFromSbsrList}
                            deactivate={deactivateUserFromSbsrList}
                            activate={activateUserFromSbsrList}
                            mult={mult}
                            passwordShown={passwordShown}
                            togglePassword={togglePassword}
                            windowWidth={windowWidth}
                        />

                    );
                })}
                <div className="container-row-bottom" style={{ width: '100%', marginTop: 25 / mult, justifyContent: 'center' }}>
                    <EmptyButton nav={ContinueClick} title={"Continue"} id={""} classname={"emptybuttonart"} />
                </div>


                <div className='container-row-center'
                    style={{
                        width: 180 / mult, height: 180 / mult, border: '3px solid #a6abb9', borderRadius: 15 / mult,
                        position: 'relative', top: -585 / mult, left: 920 / mult
                    }}
                >
                    <span style={{ textAlign: 'center', color: '#e6e6e6', fontSize: 30 / mult }}>Price:<br />${subscriptionData.cost / 100}</span>
                </div>

                {messageBox && <SimpleMsg1 target={() => { setMessageBox(false); setError("") }} text={error} title={"Continue"} mult={mult} mobile={true} />
                }

            </>
        );
    }
}

function UserBox({ user, emailhandler, p1handler, p2handler, rbhandler_fr, cbhandler_addon, rbhandler_r, cbhandler_nt, betweenlines, betweenlines2, paddingleft, remove, deactivate, activate, mult, passwordShown, togglePassword, windowWidth }: any) {
    const rbuttons_fr = [
        { "id": "rbondemand_" + user.userTempId, "title": "On demand", "buttonstate": user.frequencyOnDemand, "buttonhandler": rbhandler_fr },
        { "id": "rb3times_" + user.userTempId, "title": "3 times/day", "buttonstate": user.frequency3Times, "buttonhandler": rbhandler_fr },
        { "id": "rbdaily_" + user.userTempId, "title": "Daily", "buttonstate": user.frequencyDaily, "buttonhandler": rbhandler_fr },
        { "id": "rbweekly_" + user.userTempId, "title": "Weekly", "buttonstate": user.frequencyWeekly, "buttonhandler": rbhandler_fr },
        { "id": "rbmontly_" + user.userTempId, "title": "Monthly", "buttonstate": user.frequencyMonthly, "buttonhandler": rbhandler_fr }
    ];

    const cbbuttons_addon = [
        { "id": "ntaddon_" + user.userTempId, "title": "On Demand add-on", "buttonstate": user.notificationAddOn, "buttonhandler": cbhandler_addon, "avail": user.addOnAvailable },
    ];

    let adminrole = false; let userrole = false; let usermng = false;
    if (user.role === "user") { adminrole = false; userrole = true; usermng = false; }
    if (user.role === "admin") { adminrole = true; userrole = false; usermng = false; }
    if (user.role === "mng") { adminrole = false; userrole = false; usermng = true; }
    const rbuttons_r = [
        { "id": "rbuser_" + user.userTempId, "title": "User", "buttonstate": userrole, "buttonhandler": rbhandler_r },
        { "id": "rbmng_" + user.userTempId, "title": "Manager", "buttonstate": usermng, "buttonhandler": rbhandler_r },
        { "id": "rbadmin_" + user.userTempId, "title": "Admin", "buttonstate": adminrole, "buttonhandler": rbhandler_r },
    ];
    const cbbuttons_nt = [
        { "id": "cbweb_" + user.userTempId, "title": "Web (free)", "buttonstate": user.notificationWeb, "buttonhandler": cbhandler_nt },
        { "id": "cbemail_" + user.userTempId, "title": "Email", "buttonstate": user.notificationEmail, "buttonhandler": cbhandler_nt },
        { "id": "cbsms_" + user.userTempId, "title": "SMS/Text", "buttonstate": user.notificationSMS, "buttonhandler": cbhandler_nt }
    ];

    const emailid = "email_" + user.userTempId;
    const password1 = "pswd1_" + user.userTempId;
    const password2 = "pswd2_" + user.userTempId;
    if (windowWidth > W0) {
        return (
            <div className='container-l-l-column' style={{ width: 870 / mult, border: '3px solid #a6abb9', borderRadius: 15 / mult, padding: 15 / mult, marginBottom: 15 }}>
                <div className="container-row-center" style={{ width: '100%', fontWeight: 500, fontSize: 30 / mult, }}>
                    {user.primaryUser ?
                        ("Primary User") :

                        (<>
                            {(user.userStatus == 0) &&
                                (<>
                                    <span>New User</span>&nbsp;
                                    <span className="ac-span1 colord9d9d9" id={"newuser_" + user.userTempId} onClick={(e) => { remove(e) }} style={{ marginBottom: -3, fontSize: 20 / mult + 'px', color: '#698ff9', cursor: 'pointer' }}>(Remove)</span>
                                </>
                                )
                            }
                            {(user.userStatus == 1) &&
                                (<>
                                    <span>Active User</span>&nbsp;
                                    {/*
                                <span className="ac-span1 colord9d9d9" id={"activeuser_" + user.userTempId} onClick={(e) => { deactivate(e) }} style={{ marginBottom: -3, fontSize: 20 / mult + 'px', color: '#698ff9', cursor: 'pointer' }}>(Inactivate)</span>
                                */}
                                </>
                                )
                            }
                            {(user.userStatus == 2) &&
                                (<>
                                    <span>Inactive User</span>&nbsp;
                                    {/*
                                <span className="ac-span1 colord9d9d9" id={"inactiveuser_" + user.userTempId} onClick={(e) => { activate(e) }} style={{ marginBottom: -3, fontSize: 20 / mult + 'px', color: '#698ff9', cursor: 'pointer' }}>(Activate)</span>
                            */}
                                </>
                                )
                            }
                            {(user.userStatus == 3) &&
                                (
                                    <span>Banned User</span>
                                )
                            }
                        </>
                        )


                    }
                </div>
                <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
                </div>
                {user.userStatus == 0 ? (
                    <>
                        <div className="container-row-bottom" >
                            <div className="icon-user" style={{ width: 30 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;User email&nbsp;&nbsp;
                            <input
                                type="text"
                                value={user.email}
                                id={emailid}
                                onChange={(e) => { emailhandler(e) }}
                                required
                                style={{ width: 380 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="container-row-bottom" >
                            <div className="icon-user" style={{ width: 30 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;User email&nbsp;&nbsp;
                            {user.email}
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                    </>
                )}

                {user.userStatus == 0 ? (
                    <>
                        <div className="container-row-bottom" >
                            <div className="icon-lock" style={{ width: 40 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;Password&nbsp;&nbsp;
                            <input
                                value={user.password}
                                type={passwordShown ? "text" : "password"}
                                id={password1}
                                onChange={(e) => { p1handler(e) }}
                                required
                                style={{ width: 200 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }} />
                            &nbsp;&nbsp;&nbsp;Confirm Password&nbsp;&nbsp;
                            <input
                                value={user.confpassword}
                                type={passwordShown ? "text" : "password"}
                                id={password2}
                                onChange={(e) => { p2handler(e) }}
                                required
                                style={{ width: 200 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }} />

                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                        <div className="container-row-bottom">
                            <div className="icon-user" style={{ width: 30 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;Role?
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
                        </div>
                        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult }}>
                            <RadioButtonBlock buttonsarray={rbuttons_r} layout={"row"} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="container-row-bottom">
                            <div className="icon-user" style={{ width: 30 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;Role: {user.role}
                        </div>
                    </>
                )
                }


                {(user.userStatus == 0 || user.userStatus == 1) &&
                    (<>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>

                        <div className="container-row-bottom">
                            <div className="icon-clock" style={{ width: 50 / mult, height: 55 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;Search how often?
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
                        </div>
                        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult }}>
                            <RadioButtonBlock buttonsarray={rbuttons_fr} layout={"row"} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult, justifyContent: 'space-between' }}>
                            <CheckBoxBlock buttonsarray={cbbuttons_addon} layout={"row"} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>


                        <div className="container-row-bottom">
                            <div className="icon-mailunread" style={{ width: 50 / mult, height: 40 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;I would like to be notified through...
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
                        </div>
                        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult, justifyContent: 'space-between' }}>
                            <CheckBoxBlock buttonsarray={cbbuttons_nt} layout={"column"} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                    </>
                    )
                }
            </div>
        );

    } else {
        // Mobile *************************************************************
        mult = W0 / windowWidth;
        return (
            <div className='container-l-l-column' style={{ border: '3px solid #a6abb9', borderRadius: 15 / mult, padding: 10 / mult, marginBottom: 15 }}>
                <div className="container-row-center" style={{ width: '100%', fontWeight: 500, fontSize: 30 / mult, }}>
                    {user.primaryUser ?
                        ("Primary User") :

                        (<>
                            {(user.userStatus == 0) &&
                                (<>
                                    <span>New User</span>&nbsp;
                                    <span className="ac-span1 colord9d9d9" id={"newuser_" + user.userTempId} onClick={(e) => { remove(e) }} style={{ marginBottom: -3, fontSize: 20 / mult + 'px', color: '#698ff9', cursor: 'pointer' }}>(Remove)</span>
                                </>
                                )
                            }
                            {(user.userStatus == 1) &&
                                (<>
                                    <span>Active User</span>&nbsp;
                                    {/*
                                <span className="ac-span1 colord9d9d9" id={"activeuser_" + user.userTempId} onClick={(e) => { deactivate(e) }} style={{ marginBottom: -3, fontSize: 20 / mult + 'px', color: '#698ff9', cursor: 'pointer' }}>(Inactivate)</span>
                                */}
                                </>
                                )
                            }
                            {(user.userStatus == 2) &&
                                (<>
                                    <span>Inactive User</span>&nbsp;
                                    {/*
                                <span className="ac-span1 colord9d9d9" id={"inactiveuser_" + user.userTempId} onClick={(e) => { activate(e) }} style={{ marginBottom: -3, fontSize: 20 / mult + 'px', color: '#698ff9', cursor: 'pointer' }}>(Activate)</span>
                            */}
                                </>
                                )
                            }
                            {(user.userStatus == 3) &&
                                (
                                    <span>Banned User</span>
                                )
                            }
                        </>
                        )


                    }
                </div>
                <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
                </div>
                {user.userStatus == 0 ? (
                    <>
                        <div className="container-row-between" style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="icon-user" style={{ width: 30 / mult, height: 45 / mult }}>
                                </div>
                                &nbsp;&nbsp;&nbsp;Email&nbsp;&nbsp;
                            </div>
                            <input
                                type="text"
                                value={user.email}
                                id={emailid}
                                onChange={(e) => { emailhandler(e) }}
                                required
                                style={{ width: 250 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="container-row-between" >
                            <div className="icon-user" style={{ width: 30 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;User email&nbsp;&nbsp;
                            {user.email}
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                    </>
                )}

                {user.userStatus == 0 ? (
                    <>
                        <div className="container-row-between" style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="icon-lock" style={{ width: 40 / mult, height: 45 / mult }}>
                                </div>
                                &nbsp;&nbsp;&nbsp;<span>Password</span>&nbsp;&nbsp;
                            </div>
                            <input
                                value={user.password}
                                type={passwordShown ? "text" : "password"}
                                id={password1}
                                onChange={(e) => { p1handler(e) }}
                                required
                                style={{ width: 250 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                        <div className="container-row-between" style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="icon-lock" style={{ width: 40 / mult, height: 45 / mult }}>
                                </div>
                                <br />&nbsp;&nbsp;&nbsp;Confirm &nbsp;&nbsp;
                            </div>
                            <input
                                value={user.confpassword}
                                type={passwordShown ? "text" : "password"}
                                id={password2}
                                onChange={(e) => { p2handler(e) }}
                                required
                                style={{ width: 250 / mult, height: 25 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }} />
                        </div>

                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                        <div className="container-row-bottom">
                            <div className="icon-user" style={{ width: 30 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;Role?
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
                        </div>
                        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult }}>
                            <RadioButtonBlock buttonsarray={rbuttons_r} layout={"row"} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="container-row-bottom">
                            <div className="icon-user" style={{ width: 30 / mult, height: 45 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;Role: {user.role}
                        </div>
                    </>
                )
                }


                {(user.userStatus == 0 || user.userStatus == 1) &&
                    (<>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>

                        <div className="container-row-bottom">
                            <div className="icon-clock" style={{ width: 50 / mult, height: 55 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;Search how often?
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
                        </div>
                        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult }}>
                            <RadioButtonBlock buttonsarray={rbuttons_fr} layout={"column"} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult, justifyContent: 'space-between' }}>
                            <CheckBoxBlock buttonsarray={cbbuttons_addon} layout={"row"} bigfix={true} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>


                        <div className="container-row-bottom">
                            <div className="icon-mailunread" style={{ width: 50 / mult, height: 40 / mult }}>
                            </div>
                            &nbsp;&nbsp;&nbsp;I would like to be notified through...
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines2 / mult }}>
                        </div>
                        <div className="container-row-bottom" style={{ paddingLeft: paddingleft / mult, justifyContent: 'space-between' }}>
                            <CheckBoxBlock buttonsarray={cbbuttons_nt} layout={"column"} bigfix={true} />
                        </div>
                        <div className="container-row-bottom" style={{ margin: betweenlines / mult }}>
                        </div>
                    </>
                    )
                }
            </div>
        );
    }
}

export default withAuth(UpgradeAccountB);
