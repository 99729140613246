import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../functions";

const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["PaymentConfig", "PromoCode"],
  endpoints: (builder) => ({
    getProductPriceInfo: builder.query({
      query: (lookupkey) => ({
        url: `/stripe/config/${lookupkey}`,
      }),
      providesTags: ["PaymentConfig", "PromoCode"],
    }),
    getPK: builder.query({
      query: () => ({
        url: "/stripe/pkconfig",
      }),
      providesTags: ["PaymentConfig"],
    }),

    getPromoCodeFromAcc: builder.query({
      query: (userid) => ({
        url: `/stripe/getpromocodefromacc/${userid}`,
      }),
      providesTags: ["PromoCode"],
    }),

    applyPromoCode: builder.mutation({
      query: (promocodedata) => ({
        url: "stripe/applypromocode",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: promocodedata,
      }),
      invalidatesTags: ["PromoCode"],
    }),


    createPaymentIntent: builder.mutation({
      query: (r) => ({
        url: "/stripe/create-payment-intent",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: r,
      }),
    }),
    createSubscription: builder.mutation({
      query: (req) => ({
        url: `/stripe/createsubscription?userid=${req.userid}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: req.req,
      }),
    }),
  }),
});

export const { useCreateSubscriptionMutation,
  useCreatePaymentIntentMutation,
  useGetProductPriceInfoQuery,
  useGetPKQuery,
  useGetPromoCodeFromAccQuery,
  useApplyPromoCodeMutation,
} = paymentsApi;
export default paymentsApi

//         body: JSON.stringify({ paymentMethodType: 'card',  currency: 'usd',  }),